exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-governments-grants-incentives-tsx": () => import("./../../../src/pages/governments-grants-incentives.tsx" /* webpackChunkName: "component---src-pages-governments-grants-incentives-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-referral-tsx": () => import("./../../../src/pages/referral.tsx" /* webpackChunkName: "component---src-pages-referral-tsx" */),
  "component---src-pages-solutions-ductless-heat-pump-tsx": () => import("./../../../src/pages/solutions/ductless-heat-pump.tsx" /* webpackChunkName: "component---src-pages-solutions-ductless-heat-pump-tsx" */),
  "component---src-pages-solutions-index-tsx": () => import("./../../../src/pages/solutions/index.tsx" /* webpackChunkName: "component---src-pages-solutions-index-tsx" */)
}

